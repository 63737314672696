
import { defineComponent } from "vue";
import ApiService from "@/core/services/ApiService";
import SuspensionFilters from "./pending-requests/SuspensionFilters.vue";
import SuspendDonationModal from "@/components/modals/SuspendDonation.vue";
import { Modal } from "bootstrap";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default defineComponent({
  name: "suspension-table",
  components: {
    SuspensionFilters,
    SuspendDonationModal,
  },
  props: {
    widgetClasses: String,
  },
  data() {
    return {
      selected: {},
      columns: [],
      entities: [],
      filters: {},
      count: 0,
      pagination: {
        page: 1,
        perPage: 10,
        total: 0,
      },
      loading: false,
      initialized: false,
      selectedAll: false,
    };
  },
  methods: {
    retrieveData() {
      this.initialized = true;
      this.loading = true;
      ApiService.query("donor_suspensions", {
        params: {
          filters: this.filters,
          perPage: this.pagination.perPage,
          page: this.pagination.page,
        },
      }).then((response) => {
        this.$emit("onRetrieve", response.data);
        this.loading = false;
        this.entities = response.data.data;
        this.count = response.data.total;
        this.columns = response.data.columns; 
        this.pagination.total = response.data.meta.total;
        this.count = response.data.meta.total;
      }).catch((error) => {
        console.error("Error fetching suspension data:", error);
        this.loading = false;
      });
    },
    getEntityValue(entity, columnName) {
      return entity[columnName] ?? "N/A";
    },
    getColumnClass(column) {
      return column.sortable ? "sortable-column" : "";
    },
    getCustomColumnClass(columnName) {
      switch (columnName) {
      case "type":
        return "column-type";
      case "status":
        return "column-status";
      default:
        return "";
      }
    },
    getStatusText(status) {
      switch (status) {
        case "pending":
          return "Pendiente";
        case "approved":
          return "Aprobado";
        case "rejected":
          return "Rechazado";
        default:
          return "Desconocido";
      }
    },
    getStatusClass(status) {
      switch (status) {
        case "pending":
          return "warning";
        case "approved":
          return "success";
        case "rejected":
          return "danger";
        default:
          return "dark";
      }
    },
    translateStatus(status) {
    const statusTranslations = {
      pending: "Pendiente",
      approved: "Aprobado",
      rejected: "Rechazado",
    };
    return statusTranslations[status] || "Desconocido";
  },
    handleValidate(donorInactivation) {
        this.selected = {
          id: donorInactivation.id,
          donor_id: donorInactivation.donor_id,
          reason_id: donorInactivation.reason_id,
          until: donorInactivation.until,
          details: donorInactivation.details,
          type: donorInactivation.type,
        };


      this.performControlAnd(donorInactivation.donor_id,"Suspender donación", () => {
        let modal = new Modal(document.getElementById("SuspendDonation-modal"));
        modal.show();
      });
    },
    exportData() {
      Swal.fire({
        title: "Exportación en progreso",
        html: "",
        timer: 300000,
        allowOutsideClick: false,
        timerProgressBar: true,
        didOpen: () => {
          Swal.showLoading();
          ApiService.query("export/" , {
            responseType: "blob",
            params: {
              perPage: this.pagination.perPage,
              page: this.pagination.page,
              filters: this.filters,
              //  filterString: this.filters.string,
            },
          })
            .then((response) => {
              Swal.close();
              const blob = new Blob([response.data], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
              });
              const link = document.createElement("a");
              link.href = window.URL.createObjectURL(blob);
              link.download = "donantes-cidd.xlsx";
              link.click();
            })
            .catch((error) => {
              Swal.fire({
                title: "Ocurrió un error al exportar los datos",
                text: "Compruebe que la cantidad de resultados sea inferior a 12.000 y vuelva a intentarlo.",
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "Cerrar",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-danger",
                },
              });
            });
        },
      });
    },
    truncateText(text, maxLength) {
    if (!text) return "N/A";
    return text.length > maxLength ? text.slice(0, maxLength) + "..." : text;
  },
    reloadData() {
      this.retrieveData();
      Swal.fire({
        title: "El donante se suspendió con éxito",
        icon: "success",
        showConfirmButton: false,
        timer: 1500,
      });
    },
    updateFilters(newFilters) {
      this.filters = newFilters;
      this.retrieveData();
    },
    performControlAnd(donor_id, title, callback, failed?) {
        Swal.fire({
          title: title,
          width: 500,
          text: "¿La donante respondió satisfactoriamente las preguntas del cuestionario?",
          icon: "question",
          confirmButtonText: "Sí",
          showConfirmButton: true,
          showDenyButton: true,
          showCancelButton: true,
          denyButtonText: "No",
          cancelButtonText: "Cancelar",
        })
          .then((result) => {
            if (result.isConfirmed) {
              ApiService.post("checks", {
                donorId: donor_id,
                result: "ok",
                extra: "",
              }).then(() => {
                let myModal = document.getElementById("check-modal");
                if (myModal) {
                  myModal.click();
                }
                callback();
              });
            } else if (result.isDenied) {
              if (failed) {
                failed();
              }
            }
          })
          .catch((err) => {
            console.log(err);
          });
      },
  },
  mounted() {
    this.retrieveData();
  }
});
