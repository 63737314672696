
import { defineComponent, ref } from "vue";
import ApiService from "@/core/services/ApiService";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { Modal } from "bootstrap";
import moment from "moment/moment";

export default defineComponent({
  name: "SuspendDonation-modal",
  emits: ["afterSuspension"],
  props: {
    donorType: String,
    donorId: Number,
    reasonSelected: {
      type: Object,
      required: false,
      default: null,
    },
  },
  mounted() {
    ApiService.get("mobile/suspension_reasons").then((response) => {
      this.suspensionReasons = response.data.data;
    });
    
    if (this.reasonSelected) {
      this.suspendReason = this.reasonSelected.reason_id; // Precargar motivo seleccionado
      this.suspendUntil = this.reasonSelected.until;
      this.observations = this.reasonSelected.details;
      this.dontResume = !!this.reasonSelected.until; // Determinar si se seleccionó una fecha
  }
   },
  computed: {
    canSendForm() {
      return (
        (this.suspendReason == "2" && this.observations === "") ||
        this.suspendReason === ""
      );
    },
  },
  methods: {
    checkDate(date) {
      let m = moment(date);
      return !m.isAfter(moment(), "day");
    },
    triggerSignatureForm() {
      let modalito = new Modal(
        document.getElementById("suspendDonationSignatureModal")
      );
      modalito.show();
    },

    catchErrors(error) {
  if (error.response) {
    const { status, data } = error.response;

    if (status === 422 && data.errors) {
      const errorMessages = data.errors.donor_id

      Swal.fire({
        title: "Error de Validación",
        text: errorMessages,
        icon: "error",
        buttonsStyling: false,
        confirmButtonText: "Cerrar",
        customClass: {
          confirmButton: "btn btn-primary",
        },
      });
    } else {
      this.errors = error.response.data.errors
      Swal.fire({
        title: "Error",
        text: error.response.data.message,
        icon: "error",
        buttonsStyling: false,
        confirmButtonText: "Cerrar",
        customClass: {
          confirmButton: "btn btn-primary",
        },
      });
    }
  } else {
    Swal.fire({
      title: "Error",
      text: "No se pudo conectar al servidor. Por favor, inténtelo nuevamente.",
      icon: "error",
      buttonsStyling: false,
      confirmButtonText: "Cerrar",
      customClass: {
        confirmButton: "btn btn-primary",
      },
    });
  }
},

    closeModal() {
      let myModal = document.getElementById("SuspendDonation-modal");
      if (myModal) {
        myModal.click();
      }
    },
    storeSuspend() {
      ApiService.post("donors/" + this.donorId + "/suspension", {
        donor_id: this.donorId,
        until: this.suspendUntil || null,
        pickup: !this.dontPickup,
        reason: this.suspendReason, 
        details: this.observations || "",
      })
        .then((response) => {
          this.closeModal();
          this.$emit("afterSuspension");
          if (response.status === 202) {
            Swal.fire({
                title: "Solicitud Generada",
                text: "Se ha generado una solicitud de suspensión correctamente.",
                icon: "info",
                confirmButtonText: "Aceptar",
                buttonsStyling: false,
                customClass: {
                    confirmButton: "btn btn-primary",
                },
            });
        }
        })
        .catch(this.catchErrors);
    },
  },

  setup() {
    const suspendDonationModal = ref<null | HTMLElement>(null);
    return { suspendDonationModal };
  },
  data: () => {
    return {
      loading: false,
      suspendUntil: "",
      dontResume: false,
      reasonList: { 1: "No se encontrará en su domicilio", 2: "Otro" },
      suspendReason: "",
      suspensionReasons: [],
      dontPickup: true,
      observations: "",
      errors: {},
    };
  },
});
